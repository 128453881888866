import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { SkeletonUtils } from "three-stdlib";

export function RedPanda(props) {
    const group = useRef();
    const { scene, materials, animations } = useGLTF("/models/red-panda/red-panda.gltf");
    const { actions } = useAnimations(animations, group);
    const clone = useMemo(() => SkeletonUtils.clone(scene), [scene])
    const { nodes } = useGraph(clone)

    useEffect(() => {
        actions?.[props.anim].play();
    }, [actions, props.anim])

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group name="Rig">
                    <skinnedMesh
                        name="RedPandaHD"
                        geometry={nodes.RedPandaHD.geometry}
                        material={materials.Mat_RedPanda}
                        skeleton={nodes.RedPandaHD.skeleton}
                    />
                    <primitive object={nodes.root} />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/models/red-panda/red-panda.gltf");