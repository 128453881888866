export function PortfolioSlide({ overlayOpacity = .1, logoUrl = "", date="", readMoreUrl = "#", readMoreUrlDownload=false, readMoreText = "Read More", backgroundSrc = "", title = "Lorem ipsum", tags = [], description = "Lorem ipsum dolor sit amet. Alequitem no vedi eta deim." }) {

    return (<div className='tk-portfolio-slide'>
        <div className='tk-portfolio-slide__content-wrapper'>
            <a className="tk-portfolio-slide__link-wrapper" rel="noreferrer" download={readMoreUrlDownload} target={readMoreUrl.startsWith("#") ? "_self" : "_blank"} href={readMoreUrl}>
                {backgroundSrc && <img alt="Portfolio slide background" className='tk-portfolio-slide__bg-img' src={backgroundSrc}></img>}
                <div className='tk-portfolio-slide__content'>
                    <div className="tk-spacer--half-block-gap"></div>
                    <h2 className='tk-portfolio-slide__content-title'>{title}</h2>
                    <h4 className="tk-portfolio-slide__content-date">{date}</h4>
                    <div className="tk-spacer--half-block-gap"></div>
                    <div className='tk-tags'>
                        {
                            tags.map((tag) =>
                                <div style={{ background: tag.background, color: tag.color }} key={tag.key} className='tk-tag'>{tag.name}</div>
                            )
                        }
                    </div>
                </div>
            </a>
        </div>
    </div>
    )
}