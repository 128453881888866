import { Environment, Gltf, Backdrop, PerspectiveCamera } from "@react-three/drei";
import { RedPanda } from "./RedPanda";
import { useEffect, useState } from "react";
import { useMouse, useWindowSize } from "@uidotdev/usehooks";
import { gsap } from 'gsap';

export default function HomeScene() {
    const [camTransform, setCamTransform] = useState({ position: [0, 0, 0], rotation: [0, 0, 0] });
    const size = useWindowSize();
    const [mouse] = useMouse();
    const [scene, setScene] = useState();

    const sceneRef = (node) => {
        if (node) {
            setScene(node);
        }
    }

    useEffect(() => {
        let transform = {
            position: [-.7, 0.5, 2],
            rotation: [0.03 * Math.PI, -0.01 * Math.PI, 0]
        };

        if (size.width < 768) {
            transform = {
                position: [-.3, 0.65, 2.5],
                rotation: [0.03 * Math.PI, -0.01 * Math.PI, 0]
            };
        }

        setCamTransform(transform);
    }, [size])

    useEffect(() => {
        const xPerc = mouse.x / window.innerWidth;
        const yPerc = mouse.y / window.innerHeight;
        const maxOffsetX = .03;
        const maxOffsetY = .02;
        const offsetX =  (2 * maxOffsetX * xPerc) - maxOffsetX;
        const offsetY =  -1 *  (2 * maxOffsetY * yPerc) - maxOffsetY;

        if (scene) {
            const camDolly = scene.getObjectByName("cam-dolly");
            if (camDolly) {
                gsap.to(camDolly.position, { x: offsetX, y: offsetY, duration: .3 });
            }
        }
    }, [mouse, scene])

    return (
        <group ref={sceneRef}>
            <group name="cam-dolly">
                <PerspectiveCamera name="camera" rotation={camTransform.rotation} position={camTransform.position} makeDefault></PerspectiveCamera>
            </group>
            <ambientLight></ambientLight>
            <RedPanda anim={"Sit"} castShadow rotation={[0, -0.2 * Math.PI, 0]} scale={.15}></RedPanda>
            <Gltf castShadow rotation={[0, .4 * Math.PI, 0]} position={[.1, 0, -0.4]} scale={.6} src="/models/autumn-tree.glb"></Gltf>
            <Gltf castShadow rotation={[0, 0.7 * Math.PI, 0]} scale={.8} position={[-.4, 0, .3]} src="/models/laptop.gltf"></Gltf>
            <Gltf rotation={[0, .1 * Math.PI, 0]} scale={0.6} position={[.6, 0, -.3]} src="/models/grass.gltf"></Gltf>
            <Environment
                preset="sunset"
            ></Environment>

            <Backdrop position={[0, -.015, -4]} scale={[100, 10, 10]} receiveShadow floor={1} segments={20}>
                <meshStandardMaterial color="rgb(199,147,96)"></meshStandardMaterial>
            </Backdrop>

            <ambientLight intensity={0.1}></ambientLight>
            <directionalLight intensity={1} castShadow position={[8, 50, 8]}></directionalLight>
        </group>
    )
}