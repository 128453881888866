import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faSquareGithub } from "@fortawesome/free-brands-svg-icons";
import { faSquareEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from 'gsap';
import { Squash as Hamburger } from 'hamburger-react'
import { lenis } from "..";
import { useWindowSize } from "@uidotdev/usehooks";



export function Menu() {
    const menuRef = useRef();
    const [menuOpen, setMenuOpen] = useState();
    const [timeline, setTimeline] = useState();
    const [isScrollingDown, setIsScrollingDown] = useState();
    const size = useWindowSize();
    useLayoutEffect(() => {
        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
        let ctx = gsap.context(() => {
            const tl = gsap.timeline({ paused: true })
            tl.fromTo("#tk-main-menu", { pointerEvents: "none" }, { "pointerEvents": "auto" });
            tl.fromTo(".tk-header__container .tk-header__inner-container", { maxWidth: getComputedStyle(document.body).getPropertyValue("--tk--max-content-width") }, { maxWidth: "100%" }, "<");
            tl.fromTo("#tk-main-menu .tk-menu__column--l .tk-menu-column__background", { y: "-100%" }, { y: 0, }, "<");
            tl.fromTo("#tk-main-menu .tk-menu__column--r .tk-menu-column__background", { y: "100%" }, { y: 0, }, "<");
            tl.fromTo("#tk-main-menu .tk-main-menu__copyright", { opacity: 0 }, { opacity: 1 });
            tl.fromTo("#tk-main-menu .tk-menu li a", { translateY: "100%", opacity: 1 }, { y: 0, opacity: 1, stagger: .1, duration: .6 }, "<");
            tl.fromTo("#tk-main-menu .tk-menu__column--r .tk-menu__column-content > *", { x: "100%", opacity: 0 }, { x: "0%", opacity: 1, stagger: .1, duration: .6 }, "<");
            tl.duration(1.4);

            setTimeline(tl);
        }); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert(); // cleanup

    }, [menuRef, size]);


    useEffect(() => {
        if (menuOpen && timeline) {
            timeline.play();
        } else if (timeline) {
            timeline.reverse();
        }
    }, [menuOpen, timeline]);

    function closeMenu() {
        setMenuOpen(false);
    }


    useEffect(() => {
        function onScroll(evt) {
            setIsScrollingDown(lenis.direction === 1);
        }

        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    })

    return (
        <>
            <div style={{ opacity: isScrollingDown ? 0 : 1, pointerEvents: isScrollingDown ? "none" : "auto" }} className={"tk-header__container"} >
                <div className="tk-header__inner-container">
                    <div>
                        <a href="#welcome">
                            <svg className="tk-logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350.74 249.94">
                                <path d="m71.46,69.9v179.62h71.21V70.04c2.29,0,4.07,0,5.86,0,14.98-.02,29.96-.16,44.93.03,4.22.05,5.77-.99,5.71-5.56-.26-19.3-.11-38.61-.13-57.91,0-1.92-.21-3.83-.33-5.89H0v69.18h71.46Z" />
                                <path d="m350.74,249.87c-6.07-9.49-11.42-18.06-16.98-26.48-20.87-31.59-41.79-63.16-62.8-94.66-1.88-2.82-1.85-4.95.02-7.63,4.55-6.54,8.96-13.18,13.32-19.85,8.01-12.22,15.9-24.51,23.92-36.72,12.49-19.01,25.08-37.95,37.53-56.99,1.26-1.93,1.83-4.31,3.02-7.21-3.54,0-6.02,0-8.5,0-19.64-.03-39.3.43-58.92-.29-9.87-.36-17.53,1.01-21.26,11.32-.82,2.27-2.72,4.14-4.07,6.23-13.97,21.62-27.87,43.28-41.9,64.86-9.41,14.47-19.01,28.83-28.56,43.28.78,1.25,1.46,2.39,2.2,3.48,11.79,17.48,23.65,34.92,35.37,52.45,14.3,21.39,28.53,42.82,42.67,64.31,1.98,3.01,4.3,4,7.83,3.94,11.65-.2,23.3-.07,34.96-.07,13.63,0,27.25,0,42.16,0Z" />
                            </svg>
                        </a>
                    </div>
                    <div className="tk-header__hamburger" >
                        <Hamburger toggled={menuOpen} toggle={setMenuOpen}></Hamburger>
                    </div>
                </div>

            </div>
            <div ref={menuRef} id="tk-main-menu" className="tk-menu__overlay">
                <div className='tk-columns tk-menu__columns tk-style-height--100perc ' style={{ position: "relative" }}>
                    <div className='tk-column tk-menu__column tk-menu__column--l tk-column--50 tk-column--center'>
                        <div className="tk-menu-column__background"></div>
                        <div className="tk-menu__column-content">
                            <div></div>
                            <ul className="tk-menu">
                                <li className="tk-menu__item">
                                    <a onClick={closeMenu} href="#welcome">
                                        <h1>Home</h1>
                                    </a>
                                </li>
                                <li className="tk-menu__item">
                                    <a onClick={closeMenu} href="#about">
                                        <h1>About</h1>
                                    </a>
                                </li>
                                <li className="tk-menu__item">
                                    <a onClick={closeMenu} href="#portfolio">
                                        <h1>Portfolio</h1>
                                    </a>
                                </li>
                                <li className="tk-menu__item">
                                    <a onClick={closeMenu} href="#contact">
                                        <h1>Contact</h1>
                                    </a>
                                </li>
                            </ul>
                            <p className="tk-main-menu__copyright" style={{ textAlign: "center" }}>Thijs Koenraadt - Copyright {new Date().getFullYear()} © All rights reserved.</p>
                        </div>
                    </div>
                    <div className='tk-column tk-menu__column  tk-menu__column--r tk-column--50 tk-column--center'>
                        <div className="tk-menu-column__background"></div>
                        <div className="tk-menu__column-content">
                            <h1>
                                Thijs Koenraadt
                            </h1>
                            <p>
                                If you are interested in my work or if you have any questions, feel free to contact me!
                                I look forward to hearing from you soon!
                            </p>
                            <div className="tk-spacer--half-block-gap"></div>
                            <div className='tk-buttons'>
                                <div className='tk-button'><a href="mailto:thijs@thijskoenraadt.com?subject=Getting in touch! - thijskoenraadt.com">{"E-Mail > thijs@thijskoenraadt.com"}</a></div>
                            </div>
                            <div className="tk-spacer--block-gap"></div>
                            <div>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/thijs-koenraadt/">
                                    <FontAwesomeIcon color="var(--tk--color--secondary)" icon={faLinkedin} fontSize={"2rem"} fixedWidth />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://github.com/thijskoenraadt">
                                    <FontAwesomeIcon color="var(--tk--color--secondary)" icon={faSquareGithub} fontSize={"2rem"} fixedWidth />
                                </a>
                                <a href="mailto:thijs@thijskoenraadt.com?subject=Getting in touch! - thijskoenraadt.com">
                                    <FontAwesomeIcon color="var(--tk--color--secondary)" icon={faSquareEnvelope} fontSize={"2rem"} fixedWidth />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}