import { Box, ContactShadows, Environment, PerspectiveCamera } from "@react-three/drei";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Gltf } from "@react-three/drei";
import gsap from 'gsap';
import { RedPanda } from "./RedPanda";
import { Laptop } from "./Laptop";
import { useWindowSize } from "@uidotdev/usehooks";



export function AboutScene({ aboutSectionRef }) {
    const defaultCamTransform = {
        position: [0, .5, 1.4]
    }

    const size = useWindowSize();
    const mouseRef = useRef();
    const [aboutSceneGroup, setAboutSceneGroup] = useState();
    const [camTransform, setCamTransform] = useState(defaultCamTransform);
    const aboutSceneGroupRef = (node) => {
        if (node) {
            setAboutSceneGroup(node);
        }
    }

    useEffect(() => {
        let transform = defaultCamTransform;

        if (size.width < 768) {
            transform = {
                position: [0, .6, 1.35],
            };
        }

        setCamTransform(transform);
        // eslint-disable-next-line
    }, [size])

    useLayoutEffect(() => {
        let tl;
        let ctx = gsap.context(() => {
            if (aboutSceneGroup) {
                const laptopScreenGroup = aboutSceneGroup.getObjectByName("laptop-screen-group");
                const aboutCam = aboutSceneGroup.getObjectByName("about-cam");
                const aboutCamLookat = aboutSceneGroup.getObjectByName("about-cam-lookat");

                if (laptopScreenGroup && aboutCam && aboutCamLookat) {
                    tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: aboutSectionRef.current,
                            onUpdate: () => {
                                aboutCam.lookAt(aboutCamLookat.position);
                            },
                            start: "center bottom",
                            end: "bottom bottom",
                            markers: false,
                            scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                        }
                    });
                    // tl.fromTo(aboutCam.position, { y: 2.5 }, { duration: 1, y: 0.6 });
                    tl.fromTo(laptopScreenGroup.rotation, { x: 0.45 * Math.PI }, { duration: 1, x: .0 * Math.PI }, "<");
                }
            }
        });
        return () => { ctx.revert(); if (tl) { tl.kill() } } // cleanup
    }, [aboutSectionRef, aboutSceneGroup]);

    useEffect(() => {
        function onMouseMove(evt) {
            const xPerc = evt.clientX / window.innerWidth;
            const yPerc = evt.clientY / window.innerHeight
            const MOUSE_OFFSET_X = .07;
            const MOUSE_OFFSET_Z = .06;

            const offsetX = xPerc * MOUSE_OFFSET_X;
            const offsetZ = yPerc * MOUSE_OFFSET_Z;
            mouseRef.current.position.set(offsetX, 0, offsetZ);
        }

        window.addEventListener("mousemove", onMouseMove);
        return () => {
            window.removeEventListener("mousemove", onMouseMove);
        }
    }, [mouseRef]);

    return (
        <group ref={aboutSceneGroupRef}>
            <ContactShadows scale={4} far={.8} opacity={.6} ></ContactShadows>
            <Environment
                background={false} // can be true, false or "only" (which only sets the background) (default: false)
                blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
                preset={"warehouse"}
            />
            <PerspectiveCamera name="about-cam" makeDefault position={camTransform.position} ></PerspectiveCamera>
            <Box name="about-cam-lookat" visible={false} scale={.3} position={[0, 0.22, 0]}><meshBasicMaterial color="red"></meshBasicMaterial></Box>

            <group rotation={[0, -Math.PI * .10, 0]}>
                <Laptop rotation={[0, 0 * Math.PI, 0]} scale={1.5} position={[0, 0, 0]} ></Laptop>
                {/* <Gltf src="/models/CrtMonitor.gltf"></Gltf> */}
                {/* <Gltf rotation={[0, 0.03 * Math.PI, 0]} scale={.8} position={[-.15, 0, 0.4]} src="/models/keyboard.gltf"></Gltf> */}
                <group scale={0.1} rotation={[0, -1.3 * Math.PI, 0]} position={[-0, 0.03, 0.5]} >
                    <RedPanda anim={"Sleep"} ></RedPanda>
                </group>

                <group visible={false} position={[0.44, 0, 0.2]}>
                    <Gltf scale={[.6, 0.5, .6]} src="/models/mousepad.glb" rotation={[0, 0.45 * Math.PI, 0]} position={[0, 0, 0.05]}></Gltf>
                    <group rotation={[0, -0.1 * Math.PI, 0]} position={[0, 0.005, 0]} >
                        <Gltf ref={mouseRef} src="/models/computer-mouse.gltf"></Gltf>
                    </group>
                </group>
            </group>
        </group >
    );
}