export const Tags = {
    "Frontend": { key: "Frontend", name: "Frontend"},
    "Fullstack": { key: "Fullstack", name: "Fullstack" },
    "Backend": { key: "Backend", name: "Backend" },
    "API": { key: "API", name: "API"},
    "RedDot": {key: "RedDot", name:"Red Dot Award 2022"},
    "CSharp": { key: "CSharp", name: "C#" },
    "CPP": { key: "CPP", name: "C++" },
    "Unity": { key: "Unity", name: "Unity" },
    "Java": { key: "Java", name: "Java" },
    "JavaScript": { key: "JavaScript", name: "JavaScript" },
    "HTML": { key: "HTML", name: "HTML" },
    "CSS": { key: "CSS", name: "CSS" },
    "WEBGL": { key: "WEBGL", name: "WEBGL" },
    "THREEJS": {key: "THREEJS", name: "THREE.js"},
    "React": {key: "React", name: "React"},
    "DigitalTwin": { key: "DigitalTwin", name: "Digital Twin" },
    "WebSocket": {key: "WebSocket", name: "WebSocket"},
    "TypeScript": {key: "TypeScript", name: "TypeScript"},
    "MBSE": {key: "MBSE", name:"MBSE"},
    "Modeling3D": {key: "Modeling3D", name:"3D Modeling"}
}