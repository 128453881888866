import { Image, useGLTF } from "@react-three/drei";

export function Laptop(props) {
    const { nodes, materials } = useGLTF("/models/laptop.gltf");

    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Laptop.geometry}
                material={materials.PolygonOfficeMat}
                rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}
            >
            </mesh>
            <group rotation={[Math.PI / 2, 0, 0]}
                scale={0.01}>
                <group name={"laptop-screen-group"} position={[-25.965, -14.926, -2.956]}>
                    <Image scale={100} position={[26,-6,-19]} rotation={[-.592 *Math.PI , 0, 0]} url="/media/portrait-thijs.jpg">
                        <roundedPlaneGeometry args={[.48, .35, 0.01]} />
                    </Image>
                    <mesh
                        scale={[1,1,1.1]}
                        visible={true}
                        castShadow
                        receiveShadow
                        geometry={nodes.Screen_1.geometry}
                        material={materials.PolygonOfficeMat}
                    />
                    <mesh
                        visible={false}
                        castShadow
                        receiveShadow
                        geometry={nodes.Screen_2.geometry}
                        material={materials.ScreenMat}
                    />
                </group>
            </group>
        </group >
    );
}

useGLTF.preload("/models/laptop.gltf");