import './App.css';
import { Canvas, extend } from '@react-three/fiber';
import { View, useProgress } from '@react-three/drei';
import HomeScene from './Components/HomeScene';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import 'swiper/css';
import { geometry } from 'maath'
import { AboutScene } from './Components/AboutScene';
import { SyncCanvasLoc } from './Components/SyncCanvasLoc';
import { PortfolioSection } from './Components/PortfolioSection';
import { DotScreenPass } from 'three-stdlib';
import { Menu } from './Components/Menu';
import { ContactSection } from './Components/ContactSection';
import { smoothScrollToHash } from '.';

extend({ RoundedPlaneGeometry: geometry.RoundedPlaneGeometry })
extend({ DotScreenPass })

function App() {
  const appRef = useRef();
  const vizCanvasContainerRef = useRef();
  const aboutSectionRef = useRef();
  const heroShapeDividerBottomRef = useRef();
  const [heroSceneRef, setHeroSceneRef] = useState();
  const { active, progress } = useProgress();

  useLayoutEffect(() => {
    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      if (!heroSceneRef) {
        return;
      }

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: document.documentElement,
          start: "top top", // when the top of the trigger hits the top of the viewport
          end: "bottom bottom", // end after scrolling 500px beyond the start
          scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
        }
      })

      if (heroShapeDividerBottomRef.current) {
        tl.from(heroShapeDividerBottomRef.current, { height: "0px" }, "<");
      }

    }); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup

  }, [heroSceneRef, appRef, heroShapeDividerBottomRef]);

  useEffect(() => {
    if (progress === 100) {
      smoothScrollToHash(window.location.hash, true);
    }
  }, [progress]);

  return (
    <div ref={appRef} className="App">
      <header>
        <div>
          <Menu></Menu>
        </div>
      </header>
      <main>
        {
          <div className='tk-loader__container' style={{opacity: active? 1 : 0}}>
            <div className='tk-loader__content'>
              <svg className="tk-logo" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350.74 249.94">
                <path d="m71.46,69.9v179.62h71.21V70.04c2.29,0,4.07,0,5.86,0,14.98-.02,29.96-.16,44.93.03,4.22.05,5.77-.99,5.71-5.56-.26-19.3-.11-38.61-.13-57.91,0-1.92-.21-3.83-.33-5.89H0v69.18h71.46Z" />
                <path d="m350.74,249.87c-6.07-9.49-11.42-18.06-16.98-26.48-20.87-31.59-41.79-63.16-62.8-94.66-1.88-2.82-1.85-4.95.02-7.63,4.55-6.54,8.96-13.18,13.32-19.85,8.01-12.22,15.9-24.51,23.92-36.72,12.49-19.01,25.08-37.95,37.53-56.99,1.26-1.93,1.83-4.31,3.02-7.21-3.54,0-6.02,0-8.5,0-19.64-.03-39.3.43-58.92-.29-9.87-.36-17.53,1.01-21.26,11.32-.82,2.27-2.72,4.14-4.07,6.23-13.97,21.62-27.87,43.28-41.9,64.86-9.41,14.47-19.01,28.83-28.56,43.28.78,1.25,1.46,2.39,2.2,3.48,11.79,17.48,23.65,34.92,35.37,52.45,14.3,21.39,28.53,42.82,42.67,64.31,1.98,3.01,4.3,4,7.83,3.94,11.65-.2,23.3-.07,34.96-.07,13.63,0,27.25,0,42.16,0Z" />
              </svg>
              <div className='tk-spacer--block-gap'></div>
              <div className='tk-loader-progress__container'>
                <div className='tk-loader-progress__bar' style={{ width: progress + "%" }}></div>
              </div>
            </div>
          </div>
        }
        <div ref={vizCanvasContainerRef} id="tk-app-canvas-container">
          <Canvas shadows eventSource={appRef}>
            <SyncCanvasLoc target={vizCanvasContainerRef}></SyncCanvasLoc>
            <View.Port></View.Port>
          </Canvas>
        </div>
        <section id="welcome" className="tk-section" style={{ position: "relative", height: "100vh" }}>
          <div style={{ left: 0, top: "50%", transform: "translateY(-50%)", width: "100%", boxSizing: "border-box", height: "100%", position: "absolute" }}>
            <View style={{ width: "100%", height: "100%" }}>
              <group ref={setHeroSceneRef}>
                <HomeScene ></HomeScene>
              </group>
            </View>
            <div ref={heroShapeDividerBottomRef} className="tk-custom-shape-divider-bottom">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className="shape-fill"></path>
              </svg>
            </div>
          </div>
          <div className="tk-section-content-wrapper tk-style-position--relative" >
            <div className='tk-columns tk-style-height--100perc ' style={{ position: "relative", zIndex: "2" }}>
              <div className='tk-column tk-column--50 tk-column--center'>
                <h3 style={{ color: "white" }}>Software Engineer / Architect</h3>
                <h1 style={{ color: "white" }}>Thijs Koenraadt, M.Sc.</h1>
                <div className="tk-spacer--half-block-gap"></div>
                <div className='tk-buttons'>
                  <div className='tk-button'><a href="#about">{"Learn more"}</a></div>
                  <div className='tk-button tk-button--outlined'><a style={{ color: "white" }} target="_blank" download href="/media/cv-thijs-koenraadt-v1.6-EN.pdf">{"View CV"}</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={aboutSectionRef} id="about" className="tk-section">
          <div className="tk-section-content-wrapper tk-style-position--relative" style={{ minHeight: "50vh" }}  >
            <div id="tk-about-viz-view__wrapper" style={{ left: 0, top: "50%", transform: "translateY(-50%)", width: "100%", boxSizing: "border-box", height: "100%", position: "absolute" }}>
              <View id='tk-about-viz-view__container' style={{ width: "100%", height: "100%", paddingLeft: "50%" }}>
                <AboutScene aboutSectionRef={aboutSectionRef}></AboutScene>
              </View>
            </div>
            <div className='tk-columns' style={{ position: "relative", zIndex: "2" }}>
              <div className='tk-column tk-column--50'>
                <h3>About</h3>
                <h1>About Me</h1>
                <p>
                  As a computer scientist and avid developer I always love a good challenge. I enjoy confidently presenting my work to others, which allows me to gather feedback and collaborate.
                </p>
                <p>
                  In my free time I fancy hobbies that can provide me with a challenge such as playing the piano and developing (VR) games.
                </p>
                <p>
                  Finally, in order to stay motivated I enjoy
                  to continiously learn new skills. I thrive in an environment that strives for innovation and is always open to new opportunities.
                </p>
                <div className="tk-spacer--half-block-gap"></div>
                <div className='tk-buttons'>
                  <div className='tk-button'><a href="#portfolio">{"Learn more"}</a></div>
                  <div className='tk-button tk-button--outlined'><a target="_blank" download href="/media/cv-thijs-koenraadt-v1.6-EN.pdf">{"View CV"}</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="portfolio" className="tk-section">
          <PortfolioSection></PortfolioSection>
        </section >
        <section id="contact" className='tk-section'>
          <ContactSection></ContactSection>
        </section>
      </main>
      <footer className='tk-section'>
        <div className="tk-section-content-wrapper" >
          <div><p style={{ textAlign: "center" }}>Thijs Koenraadt - Copyright {new Date().getFullYear()} © All rights reserved.</p></div>
        </div>
      </footer>
    </div >
  );
}

export default App;
