import { ContactShadows, Environment, Gltf, PerspectiveCamera, View, useCursor } from "@react-three/drei";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faSquareGithub } from '@fortawesome/free-brands-svg-icons'
import { RedPanda } from "./RedPanda";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from 'gsap';

export function ContactSection() {
    const sectionRef = useRef();
    const viewWrapperRef = useRef();
    const [sceneGroup, setSceneGroup] = useState();
    const [letterBoxTl, setLetterBoxTl] = useState();
    const [letterBoxTimeout, setLetterBoxTimeout] = useState();
    const [doorOpen, setDoorOpen] = useState(false);
    const [hovered, setHovered] = useState()

    const sceneGroupRef = (node) => {
        if (node) {
            setSceneGroup(node);
        }
    }
    useCursor(hovered, /*'pointer', 'auto', document.body*/)

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            if (sceneGroup) {
                const letterBoxDoor = sceneGroup.getObjectByName("LetterBoxDoor");
                const pandaGroup = sceneGroup.getObjectByName("panda-group");
                if (letterBoxDoor && pandaGroup) {
                    letterBoxDoor.rotation.set(0 * Math.PI, 0, 0);
                    const tl = gsap.timeline({
                        paused: true,
                        repeat: 0,
                    });
                    tl.fromTo(letterBoxDoor.rotation, { x: 0 * Math.PI }, { x: 0.7 * Math.PI, duration: 1, ease: "bounce.out", }, "<");
                    tl.fromTo(pandaGroup.position, { x: 0, y: 0, z: 0 }, { x: -.05, y: -.05, z: .15, duration: .3 }, "<.1");
                    tl.duration(1);
                    setLetterBoxTl(tl);
                }
            }
        });
        return () => { ctx.revert(); if (letterBoxTl) { letterBoxTl.kill(); setLetterBoxTl(null) } } // cleanup
        // eslint-disable-next-line
    }, [sectionRef, sceneGroup]);

    const toggleDoor = useCallback((forceOpen = false) => {
        clearTimeout(letterBoxTimeout);

        if (letterBoxTl) {
            if (!doorOpen || forceOpen === true) {
                letterBoxTl.play();
                setDoorOpen(true);
            } else {
                // Close the door
                letterBoxTl.reverse();
                setDoorOpen(false);

                // Let the panda bump it open again randomly
                setLetterBoxTimeout(setTimeout(() => {
                    toggleDoor(true)
                }, 1500 + Math.random() * 5000));
            }

        }
    }, [letterBoxTl, doorOpen, letterBoxTimeout])


    // Bump open the door once when in view
    useEffect(() => {
        const observerCallback = (entries) => {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    if (!entry.target.classList.contains("tk-was-in-view")) {
                        toggleDoor(true);
                    }

                    entry.target.classList.add("tk-was-in-view");
                }
            }
        }

        const observer = new IntersectionObserver(observerCallback, { threshold: .9 });
        const viewWrapper = viewWrapperRef.current;
        if (viewWrapper) {
            observer.observe(viewWrapperRef.current);
        }

        return () => {
            if (viewWrapper) { observer.unobserve(viewWrapper); };
            observer.disconnect()
        }
    }, [viewWrapperRef, toggleDoor])

    return (
        <div ref={sectionRef} className="tk-section-content-wrapper tk-style-position--relative" style={{ minHeight: "33vh" }}  >
            <div ref={viewWrapperRef} id="tk-contact-viz-view__wrapper" style={{ left: 0, top: "50%", transform: "translateY(-50%)", width: "100%", boxSizing: "border-box", height: "100%", position: "absolute" }}>
                <View id="tk-contact-viz-view__container" style={{ width: "100%", height: "100%", paddingLeft: "50%" }}>
                    <group ref={sceneGroupRef}>
                        <ContactShadows scale={6} far={.8} opacity={.6} ></ContactShadows>
                        <Environment
                            background={false} // can be true, false or "only" (which only sets the background) (default: false)
                            blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
                            preset={"warehouse"}
                        />
                        <PerspectiveCamera makeDefault rotation={[-0.1 * Math.PI, 0, 0]} position={[0, 1.3, 2]}></PerspectiveCamera>
                        <group name="panda-group">
                            <RedPanda position={[-.03, .98, 0.04]} rotation={[-0.1 * Math.PI, -0.04 * Math.PI, 0]} scale={0.06} anim="Eat"></RedPanda>
                        </group>
                        <Gltf rotation={[0, -0.05 * Math.PI, 0]} position={[0, 0, -.6]} scale={.5} src="/models/hedge.gltf"></Gltf>
                        <Gltf onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)} onClick={toggleDoor} name="letter-box" rotation={[0, Math.PI * -.1, 0]} position={[0, 0, 0]} src="/models/letter-box.gltf"></Gltf>
                    </group>
                </View>
            </div>
            <div className='tk-columns' style={{ pointerEvents: "none", position: "relative", zIndex: "2" }}>
                <div className='tk-column tk-column--50' style={{ pointerEvents: "auto" }}>
                    <h3>Contact</h3>
                    <h1>Get in touch!</h1>
                    <p>
                        If you are interested in my work or if you have any questions, feel free to contact me! <br></br>
                        I look forward to hearing from you soon!
                    </p>
                    <div className="tk-spacer--half-block-gap"></div>
                    <div className='tk-buttons'>
                        <div className='tk-button'><a href="mailto:thijs@thijskoenraadt.com?subject=Getting in touch! - thijskoenraadt.com">{"E-Mail > thijs@thijskoenraadt.com"}</a></div>
                    </div>
                    <div className="tk-spacer--block-gap"></div>
                    <div className='tk-social-buttons'>
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/thijs-koenraadt/">
                            <FontAwesomeIcon color="var(--tk--color--secondary)" icon={faLinkedin} fontSize={"2rem"} fixedWidth />
                        </a>
                        <a target="_blank" rel="noreferrer" href="https://github.com/thijskoenraadt">
                            <FontAwesomeIcon color="var(--tk--color--secondary)" icon={faSquareGithub} fontSize={"2rem"} fixedWidth />
                        </a>
                        <a href="mailto:thijs@thijskoenraadt.com?subject=Getting in touch! - thijskoenraadt.com">
                            <FontAwesomeIcon rel="noreferrer" color="var(--tk--color--secondary)" icon={faSquareEnvelope} fontSize={"2rem"} fixedWidth />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}