import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/fonts.css'
import './styles/shape-dividers.css';
import './styles/menu.css';
import './styles/header.css';
import './styles/mobile.css';
import './styles/loader.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Lenis from '@studio-freight/lenis'
import { addEffect } from '@react-three/fiber';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

// register Swiper custom elements
register();

// Register gsap plugins
gsap.registerPlugin(ScrollTrigger);
// Normalize the scrolling 
ScrollTrigger.normalizeScroll(true);

// Use lenis smooth scroll
export const lenis = new Lenis({ autoResize:true, smoothWheel: true, syncTouch: true, duration: .6 })
lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0)


// Integrate into fibers own raf loop instead of opening another
addEffect((t) => lenis.raf(t))


// Create an observer instance linked to the callback function
const observer = new MutationObserver(scrollSmooth);
observer.observe(document.documentElement, {
  attributes: false, subtree: true, childList: true
});


export function scrollSmooth(evt) {
  const anchors = document.querySelectorAll('a[href*="#"]')

  for (const anchor of anchors) {
    if (anchor.classList.contains("tk-smooth-scroll-initialised")) {
      continue;
    }

    anchor.classList.add("tk-smooth-scroll-initialised");
    const targetId = anchor.getAttribute("href");

    anchor.addEventListener("click", function (evt) {
      evt.preventDefault();

      smoothScrollToHash(targetId);
    })
  }
}

export function smoothScrollToHash(targetId, instance = false) {
  try {
    if (window.history.replaceState) {
      window.history.replaceState(null, null, targetId);
    }
    else {
      window.location.hash = targetId
    }

    lenis.scrollTo(targetId, {
      offset: 32,
      duration: instance ? 0 : 1,
      easing: (x) => {
        return -(Math.cos(Math.PI * x) - 1) / 2;
      }
    });
  } catch (e) { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
