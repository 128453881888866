import { Environment, PerspectiveCamera, Sparkles, View } from "@react-three/drei";
import { PortfolioSlide } from "./PortfolioSlide";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from 'gsap';
import { Tags } from "./Enums/Tags";
import { faCircleChevronRight, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function PortfolioSection() {
    const SLIDE_CHANGE_DURATION = .6;
    const swiperContainerRef = useRef();
    const portfolioSectionRef = useRef();
    const [sceneGroup, setSceneGroup] = useState();

    const sceneGroupRef = (node) => {
        if (node) {
            setSceneGroup(node);
        }
    }

    // Animate slider
    useEffect(() => {
        if (swiperContainerRef.current) {
            const swiperEl = swiperContainerRef.current;

            // function doSlideChangeAnim(evt) {
            //     const swiper = evt.target.swiper;
            // }

            // swiperEl.addEventListener('swiperslidechangetransitionstart', doSlideChangeAnim);

            // swiper parameters
            const swiperParams = {
                loop: false,
                slidesPerView: window.innerWidth <= 768 ? 1 : 3,
                navigation: {
                    nextEl: ".tk-swiper-next",
                    prevEl: ".tk-swiper-prev"
                },
                centeredSlides: false,
                initialSlide: 0,
                breakpoints: {
                    0: {
                        slidesPerView: 1
                    },
                    769: {
                        slidesPerView: 3,
                    },
                },
            };

            // now we need to assign all parameters to Swiper element
            Object.assign(swiperEl, swiperParams);

            // and now initialize it
            swiperEl.initialize();
        }
    }, [swiperContainerRef])

    // Gsap Scroll Animation
    useLayoutEffect(() => {
        // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
        let ctx = gsap.context(() => {
            if (!sceneGroup) {
                return;
            }

            const cam = sceneGroup.getObjectByName("cam");

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: portfolioSectionRef.current,
                    start: "top bottom", // when the top of the trigger hits the top of the viewport
                    end: "bottom top", // end after scrolling 500px beyond the start
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                }
            });
            tl.fromTo(cam.position, { y: 5 }, { y: 2.5 }, "<")



        }); // <- IMPORTANT! Scopes selector text

        return () => ctx.revert(); // cleanup

    }, [sceneGroup, portfolioSectionRef]);

    return (
        <>
            <div className="tk-custom-shape-divider-top">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className="shape-fill"></path>
                </svg>
            </div>
            <View style={{ position: "absolute", top: "1px", left: 0, width: "100%", height: "calc(100% - 2px)" }}>
                <Environment
                    background={false} // can be true, false or "only" (which only sets the background) (default: false)
                    blur={0} // blur factor between 0 and 1 (default: 0, only works with three 0.146 and up)
                    preset={"lobby"}
                />
                <ambientLight intensity={1}></ambientLight>
                <color attach="background" args={["#1C244A"]}></color>
                <fog attach="fog" args={['#1C244A', 4, 18]} />
                <Sparkles position={[0, 3, 0]} count={50} scale={[10, 6, 10]} size={3} speed={0.07} />
                <group ref={sceneGroupRef}>
                    {/* <Plane scale={[50, 30, 1]} castShadow={false} receiveShadow position={[0, 0, -.1]} rotation={[-0.5 * Math.PI, 0, 0]}><meshStandardMaterial color="#c4f522"></meshStandardMaterial></Plane> */}
                    <PerspectiveCamera name="cam" makeDefault position={[0, 2.5, 5.5]}></PerspectiveCamera>
                    <directionalLight intensity={3} castShadow position={[1, 10, 1]}></directionalLight>
                </group>

            </View >
            <div className="tk-custom-shape-divider-bottom">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className="shape-fill"></path>
                </svg>
            </div>
            <div ref={portfolioSectionRef} className="tk-section-content-wrapper" style={{ minHeight: "50vh", zIndex: 2 }} >
                <div >
                    <h3>Portfolio</h3>
                    <h1>Selected Works</h1>
                    <div className="tk-spacer--block-gap"></div>
                    <div className="tk-spacer--block-gap"></div>
                    <div className='tk-columns' style={{ position: "relative", zIndex: "2" }}>
                        <div className='tk-column tk-column--100'>
                            <swiper-container
                                init={false}
                                ref={swiperContainerRef}
                                loop={false}
                                speed={SLIDE_CHANGE_DURATION * 1000}
                                space-between={32}
                            >
                                <swiper-slide>
                                    <PortfolioSlide
                                        overlayOpacity={.3}
                                        title="Proteus: A 3D Visualization Framework for System Models"
                                        date={"July 2023"}
                                        backgroundSrc="media/proteus-results-01.png"
                                        tags={[Tags.CSharp, Tags.Java, Tags.CPP, Tags.Unity, Tags.DigitalTwin, Tags.MBSE, Tags.API]}
                                        readMoreText="View Paper"
                                        readMoreUrlDownload={true}
                                        readMoreUrl="/media/master-thesis-thijs-koenraadt-v1.8.pdf"
                                        description="A 3D visualization framework for system models, that seamlessly integrates 3D model visualization in the same environment as Digital Twins. - 2023"
                                    ></PortfolioSlide>
                                </swiper-slide>
                                <swiper-slide>
                                    <PortfolioSlide
                                        overlayOpacity={.3}
                                        title="Red Dot Award for Screenagers"
                                        date="August 2022"
                                        backgroundSrc="media/screenagers-site.png"
                                        tags={[Tags.RedDot, Tags.Frontend, Tags.JavaScript, Tags.HTML, Tags.CSS]}
                                        readMoreText="View Award"
                                        readMoreUrl="https://www.red-dot.org/project/screenagers-the-incredible-machine-61103"
                                        description="Development of the Award Winning site Screenagers that sends the viewer on a journey into the strange world of 'screenagers and the incredible machine' - 2022"
                                    ></PortfolioSlide>
                                </swiper-slide>
                                <swiper-slide>
                                    <PortfolioSlide
                                        title="Eye of Atlas"
                                        date="November 2021"
                                        backgroundSrc="/media/eye-of-atlas-bg.png"
                                        logoUrl="/media/glow-logo.svg"
                                        tags={[Tags.Frontend, Tags.TypeScript, Tags.WEBGL, Tags.API, Tags.THREEJS, Tags.WebSocket]}
                                        readMoreUrl="https://gloweindhoven.nl/project/oog-van-atlas/"
                                        description="3D visualization of the TU/e campus to
                                    simulate and control the art installation Eye of Atlas. - 2021"
                                    ></PortfolioSlide>
                                </swiper-slide>
                                <swiper-slide>
                                    <PortfolioSlide
                                        title="Portfolio Thijs Koenraadt"
                                        date="February 2024"
                                        backgroundSrc="/media/tk-portfolio-hero.png"
                                        tags={[Tags.Frontend, Tags.JavaScript, Tags.HTML, Tags.CSS, Tags.React, Tags.Modeling3D, Tags.WEBGL, Tags.THREEJS]}
                                        readMoreUrl="#welcome"
                                        description=""
                                    ></PortfolioSlide>
                                </swiper-slide>
                                {/* <swiper-slide>
                                    <PortfolioSlide
                                        title="... More coming soon"
                                        readMoreText="Contact Me"
                                        readMoreUrl="#contact"
                                        description=""
                                    ></PortfolioSlide>
                                </swiper-slide> */}
                            </swiper-container>
                            <div className="tk-spacer--block-gap"></div>
                            <div className="tk-swiper-nav__container">
                                <FontAwesomeIcon className="tk-swiper-nav-button tk-swiper-prev" color="white" icon={faCircleChevronLeft} fontSize={"3rem"} />
                                <FontAwesomeIcon className="tk-swiper-nav-button tk-swiper-next" color="white" icon={faCircleChevronRight} fontSize={"3rem"} />
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}